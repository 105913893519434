<template>
  <component :is="teamData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="teamData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.errorTeam") }}
        <b-link class="alert-link" :to="{ name: 'apps-team-list' }">
          {{ $t("message.teamList") }}
        </b-link>
        {{ $t("message.forOtherTeams") }}
      </div>
    </b-alert>

    <template v-if="teamData">
      <div>
        <b-card>
          <!-- editar equipe -->
          <h2 class="mb-2">{{ $t("message.team.editTeam") }}</h2>

          <b-row>
            <b-col md="3">
              <!-- multiple select -->
              <b-form-group label-for="nameTeams">
                <template v-slot:label>
                  {{ $t("message.tableHeader.name") }}
                </template>
                <b-form-input id="basicInput" v-model="teamData.name" />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="userId">
                <template v-slot:label>
                  {{ $t("message.team.managers") }}
                </template>

                <v-select
                  multiple
                  v-model="teamData.managers"
                  :options="userManager"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="task-users"
                  class="mt-0"
                />
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group label-for="userId">
                <template v-slot:label>
                  {{ $t("message.team.members") }}
                </template>

                <v-select
                  multiple
                  v-model="teamData.members"
                  :options="userOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="task-users"
                  class="mt-0"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
    <div class="text-right">
      <b-button
        variant="primary"
        @click="updateTeams()"
        :to="{ name: 'apps-team-list' }"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>
  </component>
</template>

<style>
.mt {
  margin-top: -0.3rem;
}
</style>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,

    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  data() {
    return {
      //select \\
      userOptions: [],
      teamOptions: [],
      userManager: [],
      //multilingual i18n \\
      locale: this.$i18n.locale,
      userData: store.state.user.userData,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },

  async created() {
    var teamList = [];
    var teamId = [];
    await axios
      .get(`${URL_API}team/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          teamList.push(element);
          teamId.push(id);
          this.teamOptions.push({ label: teamList[i], value: teamId[i] });
        }
      });
    var userList = [];
    var userId = [];
    await axios
      .get(`${URL_API}user/select_list/enabled`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].email;
          const id = response.data[i].id;
          userList.push(element);
          userId.push(id);
          this.userOptions.push({
            label: userList[i],
            value: userId[i],
          });
          if (response.data[i].userGroup === "accessControlManager") {
            this.userManager.push({ label: userList[i], value: userId[i] });
          }
        }
      });
  },

  methods: {
    updateTeams() {
      axios({
        method: "put",
        url: `${URL_API}team/${this.teamData.id}`,
        headers: getHeader(this.userData),
        data: this.teamData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },
  },

  setup() {
    const teamData = ref(null);
    axios({
      method: "get",
      url: `${URL_API}team/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        teamData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          teamData.value = undefined;
        }
      });
    return {
      teamData,
      vSelect,
    };
  },
};
</script>
